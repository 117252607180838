.input-text-box {
  display: flex;
  justify-self: center;
  max-width: 657px;
  background: #141927;
  color: white;
  padding: 10px;
  border-radius: 10px;
  border: 2px solid #141927;
  font-family: "Rubik", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 13px;

	&::after {
		border-bottom: 0px;
	}

  &::before {
    border-bottom: 0px;
    content: "";
    transition: "";
    position: relative;
    -webkit-transition: none;
    transition: none;
    pointer-events: none;
    border-color: #009afd;
  }
  &:hover {
    border-color: #009afd;
  }
  &:focus {
    border-color: #009afd;
  }
}

input:disabled {
	-webkit-text-fill-color: #807f8a !important;
}
