.match__container {
  width: 100%;
  justify-content: center;
  color: #ffffff;
  position: relative;
  background: rgb(20, 25, 39);
  margin-bottom: 3%;
  border-radius: 13px;
}

.match__action {
  flex-direction: row;
  flex: 1;
  justify-content: end;
  color: #807f8a;
}

.match_score {
  flex-direction: row;
  width: 90%;
  margin: auto;
  // background-color: #141927;
  padding: 10px;
  // border: 2px solid #212a42;
  margin-top: 10px;
  margin-bottom: 10px;
  border-radius: 20px;
  // box-shadow: 0px 0px 10px 0px #000000;
  font-size: 10px;
  font-weight: bolder;
}
.team__avatar__container {
  flex: 1;
  text-align: center;
  align-items: center;
  justify-content: space-around;
}
.team__avatar {
  height: 50px;
  width: 50px;
}

.team__score {
  flex: 7;
  flex-direction: row;
  justify-content: space-between;
  font-size: 17px;
  align-items: center;
  color: #e1811f;
}

.time_remaining-text {
  text-align: center;
  font-weight: lighter;
  font-size: 11px;
  margin-bottom: 5px;
}

.time_remaining {
  text-align: center;
  font-weight: bolder;
  font-size: 17px;
  color: #e1811f;
}

.live {
  /* width: fit-content; */
  margin-right: 5%;
  background-color: red;
  border-radius: 5px;
  /* margin: auto; */
  padding-right: 5px;
  padding-left: 5px;
  padding-bottom: 2px;
}

.play_win_btn {
  font-size: 10px;
  line-height: 10px;
  letter-spacing: 0px;
  padding: 10px;
  width: 75%;
  border-radius: 5px;
  background: #009afd;
  color: white;
  font-weight: bolder;
  align-self: center;
}

.nobg {
  background-color: #14192700;
  border: none;
  color: white;
}

.League__title {
  font-family: Rubik;
  font-size: 12px;
  font-weight: 700;
  line-height: 14px;
  letter-spacing: 0em;
  justify-content: center;
  margin-left: 2%;
  width: "45%";
}

.tennis_match_container {
  justify-content: center;
  color: #ffffff;
  background: #212a42;
  margin-bottom: 1%;
  flex: 1;
}

.tennis_matchupdate_Time {
  font-size: 12px;
  justify-content: space-between;
  flex-direction: row;
  padding: 2%;
  padding-left: 5%;
  padding-right: 5%;
  border-bottom: #2f3a57 solid 2px;
}

.tennis_matchteam_name {
  font-size: 12px;
  justify-content: space-between;
  flex-direction: row;
  padding: 3%;
  padding-left: 5%;
  padding-right: 5%;
}

.team_players_name {
  flex: 6;
  justify-content: space-evenly;
}

.team_players_score {
  flex: 6;
}
