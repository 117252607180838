.app {
  display: flex;
  flex-direction: column;
  height: 100vh;
  justify-content: flex-start;
  overflow: hidden;
}

.row1 {
  display: flex;
  flex-direction: row;
  background-color: black;
  height: 90vh;
  justify-content: space-evenly;
  width: 100%;
}
.app__container {
  position: relative;
  overflow: hidden;
}

.app__input {
  position: absolute;
  bottom: 0;
  background-color: transparent;
  width: 100%;
  justify-content: center;
  align-items: center;
  display: flex;
  flex-direction: row;
  height: 75px;
  justify-content: space-evenly;
  z-index: 100;
}

.docupload_overlay {
  background-repeat: no-repeat;
  background-position-y: top;
  background-position-x: center;
  background-size: cover;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 20;
  width: 100%;
  height: 100%;
}

.photoframe {
  top: 0;
  left: 0;
  z-index: 10;
  border-width: 1;
  border-color: red;
  border-style: dashed;
}
