.date__div {
  font-size: 15px;
  font-weight: bolder;
  flex: 1;
  text-align: center;
  color: #ffffff;
  margin: 5px;
  padding-top: 10px;
  padding-bottom: 10px;
  position: relative;
}

.date__row {
  flex-direction: row;
  flex: 1;
  width: 100%;
  justify-content: center;
  margin-top: 5px;
  margin-bottom: 10px;
  background-color: none;
  border: 0px solid;
  border-radius: 7px;
}

.date__active {
  background: #009afd;
  border-radius: 15px;
  z-index: 0;
}
