.Notification__name {
  font-size: "14px";
  font-weight: "500";

  background: -webkit-linear-gradient(94.9deg, #e4035d 0%, #38adfd 100%);
  background-clip: text;
  -webkit-text-fill-color: transparent;
  // font-weight: bold;
}

.notification {
  flex-direction: row;
  color: white;
  align-self: center;
  width: 90%;
  padding: 5px;
  margin: 5px;
  border: 2px solid #212a42;
  border-radius: 15px;
}

.notification-background-even {
  background-color: #e1811f;
}

.notification-background-odd {
  background-color: #009afd;
}
