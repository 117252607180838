.button {
  font-family: "Rubik", sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 23px;
  width: 100%;
  height: 56px;
  margin: 10px auto;
  display: Block;
  color: white;
  padding: 10px;
  border-radius: 13px;

  &:hover {
    background: #009afd;
  }
}

.primary {
  background: #009afd;
}

.selected {
  background: #141927;
  border: 2px solid #009afd;
  &::after {
    content: "";
    background-image: url("../../../asset/tick.png");
    background-size: cover;
    position: absolute;
    top: -8px;
    right: -8px;
    width: 20px;
    height: 20px;
  }
}

.dark {
  background: #141927;
  border: 2px solid #212a42;
}
