.league-table-title {
  flex-direction: row;
  font-size: 15px;
  background-color: #212a42;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  padding: 15px;
  justify-content: center;
  align-items: center;
}

.league-table-row {
  flex-direction: row;
  font-size: 14px;
  padding: 10px;
  background: #141927;
  border-bottom: 3px solid #1b2337;
  text-align: center;
}
.league-table-col-1 {
  color: white;
  font-weight: bold;
  flex: 2;
  text-align: center;
  justify-content: center;
}

.league-table-col-2 {
  color: white;
  font-weight: bold;
  font-size: 13px;
  flex: 15;
  text-align: center;
  flex-direction: row;
  justify-content: space-around;
}
.league-table-col-3 {
  color: white;
  font-weight: bold;
  flex: 3;
  text-align: center;
}

.leagueTableDropdown {
  width: 95%;
  align-self: center;
  color: white;
  font-size: 12px;
}

#groups {
  padding: 0px;
  text-align: center;
  padding-top: 5px;
  padding-bottom: 5px;
  margin-top: 10px;
  margin-bottom: 10px;
}

#select-form > div > svg {
  color: white;
}

#menu- > div:nth-child(3) {
  left: 5% !important;
  width: 90% !important;
  min-width: auto !important;
  border-radius: 10%;
}

#menu- > div:nth-child(3) > ul {
  padding: 0px !important;
  color: white;
  background: #141927;
  border: 2px solid #1b2337;
}

#menuItem {
  font-size: 12px;
  text-align: center;
  justify-content: center;
}

#menuItem.Mui-selected {
  color: white;
  background: #009afd;
}
