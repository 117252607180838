.Selected__Team {
	background: -webkit-linear-gradient(94.9deg, #e4035d 0%, #38adfd 100%);
	background-clip: text;
	-webkit-text-fill-color: transparent;
	font-weight: bold;
	font-size: 14px;
}
.highlight {
	box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;
}
.css-jcn4dz-MuiBadge-badge {
	background: none !important;
}
