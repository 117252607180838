.optionList {
  width: 100%;
  margin-bottom: 3%;
}

.optionListItem {
  background: #141927 !important;
  border: 1px solid #212a42;
  margin-bottom: 0px !important;
  padding: 15px;
  flex-direction: row;
  align-items: center;
}

.optionListItem:first-child {
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
}

.optionListItem:last-child {
  border-bottom-left-radius: 20px;
  border-bottom-right-radius: 20px;
}
