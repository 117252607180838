.match-details__react-tabs {
  flex-direction: column;
  outline: none !important;
  width: 100%;
  flex: 1;
}

.match-details__react-tabs__tab-list {
  border: 0px;
  border-bottom: 1px solid #52526b53;
  justify-content: space-evenly;
  display: flex;
  padding: 0px;
  margin: 0px;
  margin-bottom: 10px;
}

.match-details__react-tabs__tab {
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 0px 10px;
  color: #b7b7b7;
  border: 10px;
  outline: none !important;
  position: relative;
  border-bottom: 5px solid rgba(255, 255, 255, 0) !important;
}

.match-details__react-tabs__tab__text {
  font-size: 13px;
  font-weight: bolder;
  margin: auto;
}

.match-details__react-tabs__tab.react-tabs__tab--selected {
  color: white;
  border: 0px !important;
  border-bottom: 5px solid #009afd !important;
}

.match-details__icon {
  font-size: 25px;
  font-weight: lighter;
  margin: auto;
  color: #ffffff;
  border: 1px solid #4d5b9500;
  border-radius: 25px;
  padding: 5px;
}

.match-details__react-tabs__tab.react-tabs__tab--selected
  > div
  > .match-details__icon {
  background-color: #4d5b95;
  border: 0px transparent !important;
}

.match-details__border {
  background: none;
  border: 1px solid #4d5b95;
  border-radius: 30px;
  padding: 5px;
  margin: auto;
}

.match-details__react-tabs__tab-panel {
  width: 100%;
  overflow-y: scroll;
  background-color: #02030a;
}
