.promo {
  flex-direction: row;
  background-color: white;
  color: black;
  margin-left: 5px;
  margin-right: 5px;
  position: relative;
}

.promo::before {
  content: "";
  position: absolute;
  width: 10px;
  height: 10px;
  border-radius: 100%;
  transform: translate(-10px, 25px);
  background: #02030a;
  z-index: 10;
}

.promo::after {
  content: "";
  position: absolute;
  top: 30px;
  right: -4px;
  width: 10px;
  height: 10px;
  border-radius: 100%;
  background: #02030a;
}
