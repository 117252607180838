.home__react-tabs {
  flex-direction: column;
  outline: none !important;
  width: 100%;
  height: 100%;
}

.home__react-tabs__tab-list {
  border: 0px solid;
  border-radius: 15px;
  margin: auto;
  margin-bottom: 5px;
  padding: 0px;
  justify-content: center;
  display: flex;
  background-color: none;
  outline: none !important;
  overflow-x: scroll;
  width: 100%;
}

.home__react-tabs__tab {
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: center;
  padding: 10px 0px;
  color: #52526b;
  border: 10px;
  outline: none !important;
}
.home__react-tabs__tab__text {
  color: #ffffff;
  font-size: 11px;
  margin: auto;
}
.react-tabs__tab:focus:after {
  background-color: none;
  position: relative;
  border: none !important;
}
.home__react-tabs__tab.react-tabs__tab--selected {
  outline: none !important;
  background: none;
  color: #4d5b95;
  border: none !important;
  outline: none !important;
}

.home__icon {
  font-size: 25px;
  font-weight: lighter;
  border: 1px solid #393939;
  margin: auto;
  color: #ffffff;
  border-radius: 25px;
  padding: 10px;
}

.home__react-tabs__tab.react-tabs__tab--selected > div > .home__icon {
  background-color: #009afd;
  border: 0px transparent !important;
}

.home__icon__border {
  background: none;

  border-radius: 30px;
  padding: 5px;
  margin: auto;
  margin-bottom: 5px;
}

.home__react-tabs__tab-panel {
  width: 100%;
  overflow: scroll;
  background: #02030a;
  flex: 10;
}

.react-tabs__tab:focus:after {
  background-color: #232333;
  position: relative;
}
