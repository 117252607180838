.main__title {
  text-align: center;
  letter-spacing: 1.15px;
  line-height: 24px;
  font-size: 24px;
  font-weight: bolder;
  text-transform: capitalize;
  margin-bottom: 10px;
}

.description {
  text-align: center;
  font-size: 13px;
  font-weight: lighter;
}

.Choose__button {
  font-family: "Rubik", sans-serif;
  font-size: 14px;
  font-weight: 500;
  line-height: 16px;
  letter-spacing: 1.5px;
  width: 150px;
  height: auto;
  // text-align: center;
}

.play_win_modal {
  position: relative;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 80%;
  background-color: #141927;
  box-shadow: 24;
  border-radius: 15px;
  color: white;
  padding: 15px;

  &::after {
    content: "";
    background-image: url("../../../asset/bottomCorner.png");
    background-size: cover;
    position: absolute;
    bottom: 0px;
    right: 0px;
    width: 65px;
    height: 41px;
  }

  &::before {
    content: "";
    background-image: url("../../../asset/topCorner.png");
    background-size: cover;
    position: absolute;
    top: 0px;
    left: 0px;
    width: 65px;
    height: 41px;
  }
}
.options {
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
}
