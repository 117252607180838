// Available and Spent div

.points__container {
  align-items: center;
  justify-content: center;
  background: #141927;
  border-radius: 15px;
  display: flex;
  width: 90%;
  margin-top: 20px;
  align-self: center;
}
.points__div {
  flex-direction: row;
  width: 90%;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid #212a42;
  height: 100%;
  padding: 15px;
  color: #ffffff;
  font-weight: 400;
  font-size: 20px;
  line-height: 24px;
  letter-spacing: 0.15px;
}
.points__title {
  font-weight: 300;
  font-size: 13px;
  line-height: 24px;
  letter-spacing: 0.15px;
  color: #9190a4;
}
.points__count {
  display: flex;
  flex-direction: row;
  font-size: 18px;
  color: #efc319;
  background: #2b2e32;
  font-weight: bold;
  width: 50px;
  text-align: center;
  padding: 5px;
  border-radius: 5px;
  justify-content: space-evenly;
}

// Points history
.points__history__main__div {
  background: #141233;
  margin-top: 40px;
  margin-left: 3.5%;
  margin-right: 3.5%;
  border-radius: 15px;
  overflow-y: scroll;
  margin-bottom: 3%;
}
.points__history__title {
  color: #ffffff80;
  font-size: 24px;
  background: #212a42;
  height: 60px;
  border-top-left-radius: 15px;
  border-top-right-radius: 15px;
  padding: 20px;
  padding-bottom: 10px;
  padding-top: 10px;
  justify-content: center;
}

.leaderboard-title {
  flex-direction: row;
  font-size: 15px;
  background-color: #212a42;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  padding: 15px;
  justify-content: center;
  align-items: center;
}
.leaderboard-row {
  flex-direction: row;
  font-size: 20px;
  background: #141927;
  padding: 2px;
  border-bottom: 3px solid #1b2337;
  justify-content: center;
  align-items: center;
}
.leaderboard-col-1 {
  color: white;
  flex-direction: row;
  font-weight: bold;
  flex: 3;
  text-align: center;
}

.leaderboard-col-2 {
  color: white;
  font-weight: bold;
  flex: 16;
  text-align: center;
  flex-direction: row;
  justify-content: flex-start;
}
.leaderboard-col-3 {
  color: white;
  font-weight: bold;
  flex: 8;
  text-align: center;
}

.leaderboard_rank1 {
  background: #009afd;
}
.leaderboard_rank2 {
  background: #6f00fd;
}
.leaderboard_rank3 {
  background: #e1811f;
}

#leaderboard-badge > .css-1sojafy-MuiBadge-badge {
  margin-bottom: -4px;
  margin-left: 19px;
}

#leaderboard-badge-first > .css-1sojafy-MuiBadge-badge {
  margin-bottom: -6px;
  margin-left: 30px;
}

.merchant__div {
  border: 1px solid #1c243a;
  border-radius: 10px;
  background: #141927;
  margin-top: 3.05%;
  width: 85%;
  align-self: center;
  padding: 5px;
  flex-direction: row;
}
.merchant__icon {
  justify-content: center;
  align-items: center;
  width: 27%;
  border-radius: 10px;
}

.merchant__select__border {
  border-bottom: 0px;
  border-bottom-left-radius: 0px;
  border-bottom-right-radius: 0px;
}

#Contained > .MuiAvatar-img {
  object-fit: cover !important;
  width: 100%;
  height: 100%;
  text-align: center;
  color: transparent;
  text-indent: 10000px;
  border-radius: 10px;
}

.MuiAvatar-img {
  width: 100%;
  height: 100%;
  text-align: center;
  object-fit: contain;
  color: transparent;
  text-indent: 10000px;
  border-radius: 10px;
}

.Reedemption__main {
  border: 1px solid #1c243a;
  border-radius: 0px;
  width: 85%;
  align-self: center;
  padding: 5px;
  flex-direction: row;
  background-color: #212a42;
}

.Reedemption__last {
  border-bottom: 1px solid #1c243a;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
}

.Reedemption__title {
  color: "#ffffff";
  font-weight: bold;
  font-size: 16px;
  margin-bottom: 5px;
}

.Reedemption__text {
  font-weight: 500;
  font-size: 10px;
  line-height: 11px;
  margin-bottom: 5px;
  color: #ffffff;
}

.Reedem__Modal__title {
  font-weight: 500;
  font-size: 20px;
  line-height: 24px;
}

.Modal__offer__text {
  background: rgb(20, 18, 51);
  border-radius: 10px;
  text-align: center;
  font-weight: 500;
  font-size: 19px;
  line-height: 23px;
  letter-spacing: 2.25px;
  padding: 10px;
}
.balancepoint__text {
  font-family: "Rubik", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  align-items: center;
  text-align: center;
  letter-spacing: 0.15px;
  width: 241px;
}

.coupon_action_logo {
  font-size: 12px;
  font-weight: lighter;
  padding: 5px;
  margin: auto;
  background: #02030a;
  border-radius: 100%;
}
