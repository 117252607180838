.flexAuth {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  flex: 1;
}

.home-main {
  background-size: cover !important;
  width: 100%;
  margin: 0 auto;
  max-width: 800px;
  min-height: 100vh;
  flex-direction: column;
  align-items: center;
}
