.matchevents__div {
  background-color: #141927;
  border: 1px solid #212a42;
  border-radius: 0px;
  flex-direction: row;
  padding: 10px;
  padding-top: 15px;
  padding-bottom: 15px;
}

.matcheventsparent {
  width: 95%;
  height: 400px;
  overflow-y: scroll;
  color: white;
  margin: auto;
}

.loader {
  background-color: #fffff000 !important;
  width: 100%;
}

.flip {
  transform: scaleX(-1);
}

.loaderColor1 > span {
  background-color: #009afd !important;
  border-radius: 50px;
}

.loaderColor2 > span {
  background-color: #e1811f !important;
  border-radius: 50px;
}
