.matchevents__div {
  background-color: #141927;
  border: 1px solid #212a42;
  border-radius: 0px;
  flex-direction: row;
  padding: 10px;
  padding-top: 15px;
  padding-bottom: 15px;
}

.matcheventsparent {
  width: 95%;
  height: 400px;
  overflow-y: scroll;
  color: white;
  margin: auto;
}

.matcheventsparent:first-child {
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
}

.matcheventsparent:last-child {
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
}
