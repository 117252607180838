.innings {
	flex-direction: row;
	flex: 1;
	justify-content: end;
	color: #807f8a;
}
.match__container {
	width: 100%;
	justify-content: center;
	color: #ffffff;
	position: relative;
}
.match__action {
	flex-direction: row;
	flex: 1;
	justify-content: end;
	color: #807f8a;
}

.wrap {
	display: flex;
	background: #ccc;
	justify-content: space-between;
	flex-direction: row;
	width: 100%;
}
