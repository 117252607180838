.optionList {
  width: 100%;
  margin-bottom: 3%;
}

.optionListItem {
  background-color: #141233;
  margin-bottom: 1px;
  padding: 15px;
  flex-direction: row;
  align-items: center;
}

.optionListItem:first-child {
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
}

.optionListItem:last-child {
  border-bottom-left-radius: 20px;
  border-bottom-right-radius: 20px;
}
