body {
  margin: 0;
  font-family: "Rubik", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

#root {
  background-color: #02030a;
}

code {
  font-family: "Rubik", sans-serif;
}

div {
  display: flex;
  flex-direction: column;
}
