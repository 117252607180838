.react-tabs__tab-list {
  border: 0px;
  margin: 0px;
  padding: 0px;
  justify-content: center;
  display: flex;
  background-color: #141233;
  flex: 1;
}

.react-tabs {
  flex-direction: column-reverse;
  width: 100%;
  height: 92vh;
  overflow: hidden;
}

.react-tabs__tab-panel {
  width: 100%;
  overflow: scroll;
  flex: 10;
  background-color: #02030a;
}

.react-tabs__tab {
  display: flex;
  flex: 1;
  justify-content: center;
  padding: 10px 0px;
  color: #52526b;
  border: 5px solid transparent !important;
  border-bottom: none !important;
  border-right: none !important;
  border-left: none !important;
}

.react-tabs__tab:focus:after {
  background-color: none;
  position: relative;
}
.react-tabs__tab--selected {
  background: none;
  color: #4d5b95;
  border-radius: 0px;
  border: 5px solid #4d5b95 !important;
  border-bottom: none !important;
  border-right: none !important;
  border-left: none !important;
}

.icon {
  font-size: 35px;
  font-weight: lighter;
}

.action_logo {
  font-size: 15px;
  font-weight: lighter;
  padding: 10px;
  margin: 10px;
  margin-right: 15px;
  background: #02030a;
  border-radius: 100%;
}

.header_title {
  font-size: 20px;
  font-weight: bolder;
}

.match_details_league {
  flex-direction: row;
  width: 100%;
  margin-top: 20px;
  margin-bottom: 0px;
  justify-content: center;
  color: white;
}
