.modal-div {
  position: absolute;
  top: 50%;
  left: 50%;
  background-color: #141927;
  transform: translate(-50%, -50%);
  width: 80%;
  border-radius: 15px;
  padding: 2%;
  outline: none;
  box-shadow: 24;
  color: white;
  align-items: center;
}
.Modal__offer__text {
  // border: 1px solid black; */
  background: rgb(20, 18, 51);
  /* justify-content: center; */
  border-radius: 10px;
  text-align: center;
  font-weight: 500;
  font-size: 19px;
  line-height: 23px;
  letter-spacing: 2.25px;
  padding: 10px;
  text-transform: uppercase;
}

.Reedem__Modal__title {
  font-family: "Rubik", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 24px;
}
.Reedemption__title {
  background: -webkit-linear-gradient(94.9deg, #e4035d 0%, #38adfd 100%);
  background-clip: text;
  -webkit-text-fill-color: transparent;
  font-weight: bold;
  font-size: 16px;
  margin-bottom: 5px;
}
.balancepoint__text {
  font-family: "Rubik", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  align-items: center;
  text-align: center;
  letter-spacing: 0.15px;
  width: 241px;
}
.Modal__offer__text {
  // border: 1px solid black; */
  background: rgb(20, 18, 51);
  /* justify-content: center; */
  border-radius: 10px;
  text-align: center;
  font-weight: 500;
  font-size: 19px;
  line-height: 23px;
  letter-spacing: 2.25px;
  padding: 10px;
}
.Add__Friend__Name {
  background: -webkit-linear-gradient(94.9deg, #e4035d 0%, #38adfd 100%);
  background-clip: text;
  -webkit-text-fill-color: transparent;
  margin-bottom: 5px;
  font-family: "Rubik", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 29px;
  line-height: 128%;
}
